@import 'bootstrap/scss/bootstrap.scss';

.nav-masthead {
  .nav-link {
    color: rgba(255, 255, 255, .5);
    border-bottom: .25rem solid transparent;
  }

  .nav-link:hover, .nav-link:focus {
    border-bottom-color: rgba(255, 255, 255, .25);
  }

  .nav-link + .nav-link {
    margin-left: 1rem;
  }

  .active {
    color: #fff;
    border-bottom-color: #fff;
  }
}
main {
  padding-bottom: 30px;
  @include media-breakpoint-up(md) {
    width: 42em;
    margin-left: auto;
    margin-right: auto;
  }
}
footer {
  .copyright {
    font-size: 8pt;
  }
}
.dark-box {
  @extend .bg-opacity-50;
  @extend .text-bg-dark;
  @extend .text-light;
  border-radius: 10px;
}
